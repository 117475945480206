import { BellAlertIcon } from '@heroicons/react/20/solid';
import { BellIcon } from '@heroicons/react/24/outline';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { useSearch } from 'hooks/useSearch';
import { memo, useState } from 'react';

import {
  CrudeCard,
  DrawdownCard,
  FullCycleCard,
  GoldCard,
  LatestModelUpdateCard,
  NotificationBar,
  PageHeader,
  RaaCard,
  RecentPubCard,
  RecentRetroCard,
  SearchBar,
  SectorRotationCard,
  SimpleLineIcon,
  StrategicAA,
} from 'components';
import { STORAGE_KEYS } from 'constants/storage';
import Head from 'next/head';

const pageTitle = 'Home | 3FR';

const Dashboard = function () {
  const { onSubmit } = useSearch('page');
  const isSmallScreen = useMediaQuery(768);
  const [isNotificationVisible, setIsNotificationVisible] = useState(() => {
    // Initialize based on localStorage, but allow it to be shown again
    if (typeof window !== 'undefined') {
      return localStorage.getItem(STORAGE_KEYS.DEEPSEEK_NOTIFICATION) !== 'true';
    }
    return true;
  });

  return (
    // overflow: hidden to hide StratAA overflow on mobile
    <div className='bg-zinc-100 min-h-screen overflow-x-hidden lg:overflow-x-visible'>
      <Head>
        <title>{pageTitle}</title>
        <meta property='og:title' content={pageTitle} key='title' />
      </Head>

      <PageHeader
        heading='Home'
        className='border-none md:border-solid md:mb-6 3xl:mb-10'
        leftEl={
          <div className='mr-2 hidden md:flex'>
            <SimpleLineIcon name='home' />
          </div>
        }
        rightEl={
          <div className='flex items-center gap-4'>
            {/* <button
              onClick={() => setIsNotificationVisible(true)}
              className='p-2 hover:bg-gray-100 rounded-full transition-colors'
              title='Show announcement'>
              <BellAlertIcon className='h-5 w-5 text-teal-600' />
            </button> */}
            {!isSmallScreen && (
              <div className='fixed md:static bottom-0 left-0 p-4 md:p-0 z-[5] bg-neutral-200 md:bg-transparent w-screen md:w-[50vw] lg:w-[33vw]'>
                <SearchBar onSubmit={onSubmit} />
              </div>
            )}
          </div>
        }
      />

      {/* <NotificationBar
        isVisible={isNotificationVisible}
        onDismiss={() => setIsNotificationVisible(false)}
      /> */}

      <div className='px-4 md:px-6 pb-4 md:pb-6'>
        <div className='lg:grid grid-cols-2 gap-5 mb-6 lg:mb-5 3xl:mb-12'>
          <div className='flex flex-col mb-4 lg:mb-0'>
            <div className='flex items-baseline'>
              <div className='mr-2 '>
                <SimpleLineIcon name='book-open' size={16} />
              </div>
              <h2 className=' font-medium uppercase mb-3'>Strategic AA Recommendations</h2>
            </div>
            <StrategicAA />
          </div>
          <div className='flex flex-col'>
            <div className='flex items-baseline'>
              <div className='mr-2 '>
                <SimpleLineIcon name='book-open' size={16} />
              </div>
              <h2 className=' font-medium uppercase mb-3'>Recent Commentary</h2>
            </div>
            <div className='h-full flex flex-1 flex-col justify-between'>
              <div className='mb-4 lg:mb-2 3xl:mb-4 h-full'>
                <RecentPubCard />
              </div>
              <div className='mb-4 lg:mb-2 3xl:mb-4 h-full'>
                <LatestModelUpdateCard />
              </div>
              <div className='h-full'>
                <RecentRetroCard />
              </div>
            </div>
          </div>
        </div>
        <div className='flex items-baseline'>
          <div className='mr-2 '>
            <SimpleLineIcon name='graph' size={16} />
          </div>
          <h2 className='font-medium uppercase mb-3'>Models</h2>
        </div>
        <div className='w-full mb-4 md:mb-5 3xl:mb-12 grid gap-4 md:gap-5 grid-cols-3 md:grid-cols-3'>
          <CrudeCard cardId='dash-crude' />
          <GoldCard cardId='dash-gold' />
          <DrawdownCard cardId='dash-drawdown' />
        </div>
        <div className='w-full grid gap-4 md:gap-5 grid-cols-11'>
          <div className='col-span-11 lg:col-span-3'>
            <RaaCard cardId='dash-raa' />
          </div>
          <div className='col-span-11 lg:col-span-5'>
            <FullCycleCard cardId='dash-full-cycle' />
          </div>
          <div className='col-span-11 lg:col-span-3'>
            <SectorRotationCard cardId='dash-sector' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(Dashboard);
