import { formatPercent, weightGreen, weightRed } from '@3fourteen/core';
import dayjs from 'dayjs';
import { classNames } from 'helpers/classNames';
import { destructureDate } from 'helpers/destructureDate';
import { useCommentary } from 'hooks/useCommentary';
import { memo } from 'react';

import { AntIcon, PostIcon } from 'components';
import Link from 'next/link';

interface OurPicksTableRowProps {
  row: any;
  inactiveRow?: boolean;
}

function OurPicksTableRow({ row, inactiveRow = false }: OurPicksTableRowProps) {
  const date = row.cells[0].value;
  const { year, month, day } = destructureDate(date);

  const { commentary } = useCommentary(['commentary', { day, month, year, first: 1 }]);

  return (
    <tr
      key={row.original.id}
      className={classNames(
        inactiveRow ? 'opacity-50 italic' : undefined,
        'border-y border-solid border-zinc-200'
      )}>
      {row.cells.map((cell, i) => {
        const isDateCell = i === 0 || i === 5;
        const isWeightCell = i === 2 || i === 3;
        const isTickerCell = i === 1;
        const isPctChangeCell = i === 2;
        const isReportCell = i === 4;
        const report = commentary?.[0];
        const weightCellClass = isPctChangeCell ? 'font-semibold' : undefined;
        const width = isWeightCell || isTickerCell ? '13%' : isReportCell ? '33%' : '14%';

        return (
          <td
            role='cell'
            key={cell.getCellProps().key}
            width={width}
            className='first:pl-4 md:first:pl-6 border-r border-solid border-zinc-100 px-3 py-0 text-15 whitespace-nowrap'>
            {isDateCell && (
              <span className='text-13 uppercase py-1.5 inline-block'>
                {!!cell.value ? dayjs(cell.value).format('MMMM D, YYYY') : ''}
              </span>
            )}
            {isTickerCell && cell.render('Cell')}
            {isWeightCell && (
              <span className={`inline-block w-14 text-right mr-0.5 ${weightCellClass}`}>
                {formatPercent(cell.value)}
              </span>
            )}
            {isReportCell && (
              <Link href={`/${report?.categorySlug}/${report?.slug}`} className='flex items-center'>
                <PostIcon categorySlug={report?.categorySlug} size={14} />
                <span className='font-serif text-15 inline-block ml-1'>{report?.title}</span>
              </Link>
            )}
            {isPctChangeCell && !!cell.value && (
              <span className='not-italic top-[2px] relative'>
                <AntIcon
                  name={cell.value > 0 ? 'caretup' : 'caretdown'}
                  color={cell.value > 0 ? weightGreen : weightRed}
                />
              </span>
            )}
          </td>
        );
      })}
    </tr>
  );
}
export default memo(OurPicksTableRow);
