'use client';

import { Dialog } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { useSignedCloudfrontUrl } from 'hooks/useSignedCloudfrontUrl';
import { memo, useEffect, useState } from 'react';

import { VideoPlayer } from 'components';
import { STORAGE_KEYS } from 'constants/storage';

interface NotificationBarProps {
  isVisible: boolean;
  onDismiss: () => void;
}

const NotificationBar: React.FC<NotificationBarProps> = ({
  isVisible: initialVisibility,
  onDismiss,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { url, error } = useSignedCloudfrontUrl(`retrospectives/2025/2025-01-27/2025-01-27.mp4`);

  useEffect(() => {
    const isDismissed = localStorage.getItem(STORAGE_KEYS.DEEPSEEK_NOTIFICATION) === 'true';
    if (isDismissed && initialVisibility) {
      onDismiss();
    }
  }, []);

  const handleDismiss = () => {
    localStorage.setItem(STORAGE_KEYS.DEEPSEEK_NOTIFICATION, 'true');
    onDismiss();
  };

  if (!initialVisibility) return null;

  return (
    <>
      <div className='bg-teal-600 text-white mx-4 px-4 py-2 rounded-lg shadow-lg mb-4 -mt-6 relative z-[2]'>
        <div className='max-w-7xl mx-auto flex flex-col md:flex-row md:items-center justify-between gap-3 md:gap-0'>
          <div className='flex flex-col lg:flex-row items-center'>
            <span className='font-medium mr-2'>Announcement:</span>
            <span>
              DeepSeek: Warren & Fernando give an impromptu discussion on the race between AI
              adoption & efficiency
            </span>
          </div>
          <div className='flex items-center gap-4 self-end md:self-auto'>
            <button
              onClick={() => setIsModalOpen(true)}
              className='bg-white text-teal-600 px-4 py-1.5 rounded-full text-sm font-medium hover:bg-teal-50 transition-colors'>
              Watch Now
            </button>
            <button
              onClick={handleDismiss}
              className='text-white hover:text-teal-100 transition-colors'
              aria-label='Dismiss announcement'>
              <XMarkIcon className='h-5 w-5' />
            </button>
          </div>
        </div>
      </div>

      <Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)} className='relative z-50'>
        <div className='fixed inset-0 bg-black/30' aria-hidden='true' />

        <div className='fixed inset-0 flex items-center justify-center p-4'>
          <Dialog.Panel className='mx-auto w-full max-w-3xl bg-white rounded-lg shadow-xl overflow-hidden'>
            <div className='flex justify-between items-center px-6 py-4 border-b'>
              <Dialog.Title className='text-lg font-medium text-gray-900'>
                DeepSeek Discussion: The race between AI adoption and efficiency
              </Dialog.Title>
              <button
                onClick={() => setIsModalOpen(false)}
                className='p-2 rounded-lg hover:bg-gray-100 transition-colors'
                title='Close'>
                <XMarkIcon className='h-5 w-5 text-gray-700' />
              </button>
            </div>
            <div className='p-6'>
              <div className='aspect-video'>
                <VideoPlayer src={url} showChapterLabels={false} />
              </div>
            </div>
            <div className='px-6 pb-8'>
              <span className='font-medium mb-2 block'>Key takeaways:</span>
              <ul className='ml-4 space-y-2'>
                <li className='list-disc'>
                  Improving efficiency has been a theme for a long time,{' '}
                  <a
                    className='text-sky-700 font-semibold'
                    href='/publications/infinite-returns-tracking-ais-capex-appetite'
                    target='_blank'
                    rel='noopener noreferrer'>
                    we even wrote about it being a big risk to Nvidia last year
                  </a>
                  .
                </li>
                <li className='list-disc'>
                  Cheaper AI is good for Nvidia&apos;s customers (the rest of the Mag 7), allowing
                  them to ease their Capex spend while still being able to improve their products
                  and increase revenue.
                </li>
                <li className='list-disc'>
                  Look to big tech earnings calls for clues on executive sentiment around continued
                  AI capex. Do they continue to believe AI adoption advances quickly enough to
                  overcome efficiency gains?
                </li>
              </ul>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
    </>
  );
};

export default memo(NotificationBar);
