'use client';

import { memo, useEffect, useState } from 'react';

import { CustomResearchNavLink, NavLink } from 'components';
import { accountLinks, navLinks, ParentItem } from 'services/links';

export const CoreNav = () => {
  const [expandedSections, setExpandedSections] = useState<ParentItem[]>(() => {
    // Try to get saved state from localStorage on initial render
    if (typeof window !== 'undefined') {
      const saved = localStorage.getItem('navExpandedSections');
      return saved ? JSON.parse(saved) : ['models', 'stock-selection', 'charts']; // default open sections
    }
    return ['models', 'stock-selection', 'charts']; // fallback for SSR
  });

  // Save to localStorage whenever expandedSections changes
  useEffect(() => {
    localStorage.setItem('navExpandedSections', JSON.stringify(expandedSections));
  }, [expandedSections]);

  const toggleSection = (section: ParentItem) => {
    setExpandedSections((prev) =>
      prev.includes(section) ? prev.filter((s) => s !== section) : [...prev, section]
    );
  };

  return (
    <div className='flex-1 flex flex-col justify-between'>
      <nav className='flex flex-col text-white'>
        {navLinks.map(({ displayName, route, childOf, parent, icon, iconFamily, banner }) => {
          let onClick = undefined;

          if (!!parent) {
            onClick = () => toggleSection(parent);
          }

          return (
            <NavLink
              href={route}
              text={displayName}
              key={displayName}
              icon={icon}
              onClick={onClick}
              childOf={childOf}
              iconFamily={iconFamily}
              parent={parent}
              banner={banner}
              isExpanded={!!expandedSections.includes(parent)}
              visible={!!childOf ? !!expandedSections.includes(childOf) : true}
            />
          );
        })}
        <CustomResearchNavLink />
      </nav>
      <nav className='flex flex-col text-white mt-4'>
        {accountLinks.map(({ displayName, route, icon, onClick, iconFamily }) => (
          <NavLink
            href={route}
            text={displayName}
            key={displayName}
            icon={icon}
            onClick={onClick}
            iconFamily={iconFamily}
            className='capitalize'
          />
        ))}
      </nav>
    </div>
  );
};

CoreNav.displayName = 'CoreNav';

export default memo(CoreNav);
