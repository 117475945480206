import { memo, ReactNode } from 'react';

interface PageHeaderProps {
  heading: string | ReactNode;
  className?: string;
  leftEl?: ReactNode;
  rightEl?: ReactNode;
  banner?: string;
}

function PageHeader({ heading, className, leftEl, rightEl, banner }: PageHeaderProps) {
  return (
    <div
      className={`h-[50px] md:h-[80px] md:px-6 flex-shrink-0 flex justify-between items-center border-b border-solid border-black/20 ${className}`}>
      <div className='flex items-center flex-1 justify-center md:justify-start'>
        <div className='flex items-baseline'>
          {leftEl ? leftEl : null}
          {typeof heading === 'string' ? (
            <h1 className='flex-1 text-xl md:text-2xl text-center md:text-left font-semibold'>
              {heading}
            </h1>
          ) : (
            heading
          )}
        </div>
        {banner && (
          <div className='text-xs text-white bg-teal-500 px-3 ml-2 font-black rounded-sm shadow-lg'>
            {banner}
          </div>
        )}
      </div>
      {rightEl ? rightEl : null}
    </div>
  );
}

PageHeader.displayName = 'PageHeader';

export default memo(PageHeader);
