'use client';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import '@react-pdf-viewer/thumbnail/lib/styles/index.css';

import { Chart, Commentary, Retrospective } from '@3fourteen/core';
import {
  ArrowDownTrayIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  CodeBracketIcon,
  MagnifyingGlassIcon,
  MagnifyingGlassMinusIcon,
  MagnifyingGlassPlusIcon,
  Squares2X2Icon,
} from '@heroicons/react/24/outline';
import * as Popover from '@radix-ui/react-popover';
import { DocumentLoadEvent, Viewer, Worker } from '@react-pdf-viewer/core';
import { RenderGoToPageProps } from '@react-pdf-viewer/page-navigation';
import { printPlugin } from '@react-pdf-viewer/print';
import { thumbnailPlugin } from '@react-pdf-viewer/thumbnail';
import { toolbarPlugin, ToolbarSlot } from '@react-pdf-viewer/toolbar';
import { RenderZoomInProps, RenderZoomOutProps, RenderZoomProps } from '@react-pdf-viewer/zoom';
import { downloadFile } from 'helpers/downloadFile';
import { useCustomResearch } from 'hooks/useCustomResearch';
import { useMember } from 'hooks/useMember';
import { useSignedCloudfrontUrl } from 'hooks/useSignedCloudfrontUrl';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';

import {
  AntIcon,
  ChartBuilderMenu,
  CommentaryRenderer,
  CrsCsvDownload,
  DataDownload,
  FavoriteButton,
  SimpleLineIcon,
  Tooltip,
} from 'components';
import { AudioPlayer } from 'components/AudioPlayer/component';
import { usePathname } from 'next/navigation';
import { trackDownloadedFile, trackToggledPostViewingPreference } from 'services/mixpanel';

interface PostViewerProps {
  url: string;
  filename: string;
  defaultScale?: number;
  showToolbar?: boolean;
  containerClass?: string;
  withCredentials?: boolean;
  meta?: {
    post: Chart | Commentary | Retrospective;
  };
}

function PostViewer({
  url,
  filename,
  meta,
  defaultScale,
  containerClass,
  showToolbar = true,
  withCredentials = false,
}: PostViewerProps) {
  const toolbarPluginInstance = toolbarPlugin();
  const printPluginInstance = printPlugin();
  const thumbnailPluginInstance = thumbnailPlugin();

  const { print } = printPluginInstance;
  const { Toolbar } = toolbarPluginInstance;
  const { Thumbnails } = thumbnailPluginInstance;

  const audioKey = useMemo(() => {
    if (meta?.post) {
      const isoDate = meta?.post?.date.split('T')[0];
      const year = isoDate?.split('-')[0];

      return `${meta?.post?.categorySlug}/${year}/${isoDate}/audio.mp3`;
    } else {
      return undefined;
    }
  }, [meta]);

  const { url: audioUrl } = useSignedCloudfrontUrl(audioKey);

  const { isTrialUser } = useMember();
  const { data } = useCustomResearch();
  const pathname = usePathname();
  const hasAnchor = !!(pathname || '').split('#')[1];
  const isCustomResearch = pathname.includes('/custom-research');
  const { url: signedDataUrl } = useSignedCloudfrontUrl(
    (meta?.post as Chart)?.dataUrl?.split('/')?.pop() || ''
  );

  const showSearch =
    meta?.post.categorySlug === 'publications' ||
    meta?.post.categorySlug === 'model-updates' ||
    meta?.post.categorySlug === 'chart-books';
  const [numPages, setNumPages] = useState(0);
  const hasMultiplePages = numPages > 1;
  const [dynamicUrl, setDynamicUrl] = useState('');
  const [showThumbnails, setShowThumbnails] = useState(false);

  const onDocumentLoad = (e: DocumentLoadEvent) => {
    setNumPages(e.doc.numPages);
  };

  const [viewingHtml, setViewingHtml] = useState(false);

  useEffect(() => {
    const isHtml = localStorage.getItem('isHtml');
    const isViewingHtml = isHtml === 'true';

    if (isViewingHtml || hasAnchor) {
      setViewingHtml(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleView = useCallback(() => {
    const newState = !viewingHtml;
    trackToggledPostViewingPreference(newState);
    localStorage.setItem('isHtml', String(newState));
    setViewingHtml(newState);
  }, [viewingHtml]);

  const toggleThumbnails = useCallback(() => {
    setShowThumbnails((prev) => !prev);
  }, []);

  return (
    <div
      style={{ height: isMobile ? 'auto' : '100vh', overflow: viewingHtml ? 'auto' : 'hidden' }}
      className={containerClass}>
      <Worker workerUrl='https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js'>
        {showToolbar && (
          <div className='px-4 fixed bottom-0 left-0 right-0 lg:relative overflow-x-auto lg:overflow-visible flex lg:justify-center h-12 z-[6] border-b border-solid border-zinc-300 bg-[#eee] whitespace-nowrap'>
            <div className='flex items-center justify-center'>
              {!viewingHtml && (
                <Toolbar>
                  {(slots: ToolbarSlot) => {
                    const {
                      CurrentPageInput,
                      GoToNextPage,
                      GoToPreviousPage,
                      NumberOfPages,
                      ShowSearchPopover,
                      Zoom,
                      ZoomIn,
                      ZoomOut,
                    } = slots;
                    return (
                      <div className='flex items-center justify-center lg:mr-2'>
                        <div className='flex  justify-center items-center lg:divide-x divide-solid divide-zinc-300 lg:space-x-2'>
                          {hasMultiplePages && (
                            <button
                              onClick={toggleThumbnails}
                              className='rounded hover-teal px-2 py-1'>
                              <Squares2X2Icon className='size-5' />
                            </button>
                          )}
                          {showSearch && !isMobile && (
                            <ShowSearchPopover>
                              {(props) => (
                                <button
                                  onClick={props.onClick}
                                  className='flex items-center  ml-2 rounded hover-teal px-2 py-1'>
                                  <MagnifyingGlassIcon className='size-5' />
                                </button>
                              )}
                            </ShowSearchPopover>
                          )}
                          <div className='flex items-center justify-center pl-2 lg:pl-4'>
                            <ZoomOut>
                              {(props: RenderZoomOutProps) => (
                                <button
                                  onClick={props.onClick}
                                  className='rounded hover-teal pl-2 pr-3 lg:pr-2 py-1'>
                                  <MagnifyingGlassMinusIcon className='size-5' />
                                </button>
                              )}
                            </ZoomOut>
                            {!isMobile && <Zoom />}
                            <ZoomIn>
                              {(props: RenderZoomInProps) => (
                                <button
                                  onClick={props.onClick}
                                  className='rounded hover-teal px-2 py-1'>
                                  <MagnifyingGlassPlusIcon className='size-5' />
                                </button>
                              )}
                            </ZoomIn>
                          </div>
                          {!isMobile && hasMultiplePages && (
                            <div className='flex  px-4 items-center !border-r border-solid border-zinc-300 text-sm font-medium'>
                              <GoToPreviousPage>
                                {(props: RenderGoToPageProps) => (
                                  <button
                                    disabled={props.isDisabled}
                                    onClick={props.onClick}
                                    className='p-1 hover-teal rounded'>
                                    <ChevronUpIcon className='size-5' />
                                  </button>
                                )}
                              </GoToPreviousPage>
                              <CurrentPageInput /> / <NumberOfPages />
                              <GoToNextPage>
                                {(props: RenderGoToPageProps) => (
                                  <button
                                    disabled={props.isDisabled}
                                    onClick={props.onClick}
                                    className='p-1 hover-teal rounded'>
                                    <ChevronDownIcon className='size-5' />
                                  </button>
                                )}
                              </GoToNextPage>
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  }}
                </Toolbar>
              )}
              <div className='flex items-center divide-x divide-solid divide-zinc-300 space-x-2 py-1'>
                <div>
                  <div className='flex justify-center items-center '>
                    {data && isCustomResearch && (
                      <div className='ml-3 mr-0.5 rounded hover-teal '>
                        <ChartBuilderMenu onUpdate={setDynamicUrl} />
                      </div>
                    )}

                    {meta?.post.categorySlug === 'charts' && !!(meta?.post as Chart)?.excerpt && (
                      <Popover.Root>
                        <Popover.Trigger>
                          <div className='flex cursor-pointer pl-6'>
                            <SimpleLineIcon name='info' className='!text-lg !lg:text-xl mr-3' />
                          </div>
                        </Popover.Trigger>
                        <Popover.Content>
                          <div className='py-4 px-8 bg-white rounded max-w-[300px] lg:max-w-[400px] border border-solid border-black/10 shadow-lg'>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: meta?.post?.excerpt,
                              }}
                            />
                          </div>
                        </Popover.Content>
                      </Popover.Root>
                    )}
                    {meta?.post && (
                      <div className='rounded hover-teal mx-0.5 px-2 py-1'>
                        <FavoriteButton post={meta.post} />
                      </div>
                    )}
                    <div className='px-1'>
                      {!isTrialUser ? (
                        <button
                          onClick={() => {
                            trackDownloadedFile({ filename });
                            downloadFile(url, filename);
                          }}
                          className='border-none flex items-center rounded hover-teal px-2 py-1'>
                          <ArrowDownTrayIcon className='size-4 lg:size-5 text-[#373e41]' />
                        </button>
                      ) : (
                        <div
                          data-tooltip-id='download-tip'
                          data-tooltip-content='Upgrade to download'>
                          <ArrowDownTrayIcon className='size-4 lg:size-5 text-[#373e41] opacity-50 cursor-not-allowed' />

                          <Tooltip id='download-tip' />
                        </div>
                      )}
                    </div>

                    <div className='px-1 flex items-center'>
                      {!isTrialUser ? (
                        <button
                          onClick={print}
                          className='border-none flex items-center rounded hover-teal px-2'>
                          <AntIcon name='printer' className='!text-lg lg:!text-xl' />
                        </button>
                      ) : (
                        <div data-tooltip-id='print-tip' data-tooltip-content='Upgrade to print'>
                          <AntIcon
                            name='printer'
                            className='!text-lg lg:!text-xl cursor-not-allowed opacity-50'
                          />
                          <Tooltip id='print-tip' />
                        </div>
                      )}
                    </div>
                    {isCustomResearch && (
                      <div className='ml-1 rounded hover-teal px-2 py-0.5'>
                        <CrsCsvDownload chartCode={filename.split('.')[0]} />
                      </div>
                    )}
                    {!!(meta?.post as Chart)?.dataUrl && (
                      <div className='ml-3'>
                        <DataDownload
                          url={signedDataUrl}
                          filename={(meta?.post as Chart)?.dataUrl}
                        />
                      </div>
                    )}
                    {!!(meta?.post as Commentary)?.content && (
                      <div className='px-1'>
                        <button
                          onClick={toggleView}
                          className='border-none flex items-center rounded hover-teal px-2 py-1'
                          title={viewingHtml ? 'View PDF' : 'View HTML'}>
                          {viewingHtml ? (
                            <AntIcon name='pdffile1' className='!text-lg lg:!text-xl' />
                          ) : (
                            <CodeBracketIcon className='text-[#373e41] size-5' />
                          )}
                        </button>
                      </div>
                    )}
                  </div>
                </div>

                <div className='pl-6'>
                  <AudioPlayer src={audioUrl} />
                </div>
              </div>
              <div className=''></div>
            </div>
          </div>
        )}
        {viewingHtml && !!(meta?.post as Commentary)?.content && (
          <CommentaryRenderer post={meta?.post as Commentary} />
        )}
        <div className='flex h-[calc(100vh-57px)]'>
          {showThumbnails && (
            <div className='flex-shrink-0 border-r border-solid border-zinc-300 px-6'>
              <Thumbnails />
            </div>
          )}
          <div className='flex-1'>
            <Viewer
              fileUrl={dynamicUrl || url}
              // @ts-ignore
              plugins={[toolbarPluginInstance, printPluginInstance, thumbnailPluginInstance]}
              defaultScale={defaultScale}
              onDocumentLoad={onDocumentLoad}
              withCredentials={withCredentials}
            />
          </div>
        </div>
      </Worker>
    </div>
  );
}

export default memo(PostViewer);
