'use client';

import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useFullscreen, useToggle, useVideo } from 'react-use';

import { AntIcon, VideoControlBar } from 'components';
import { usePathname } from 'next/navigation';
import { routes } from 'services/routes';

interface VideoPlayerProps {
  src: HTMLVideoElement['src'];
  poster?: HTMLVideoElement['poster'];
  chaptersSrc?: HTMLTrackElement['src'];
  showChapterLabels?: boolean;
}

function VideoPlayer({ src, poster = '', chaptersSrc, showChapterLabels }: VideoPlayerProps) {
  const chaptersRef = useRef<HTMLTrackElement>(null);
  const fullScreenRef = useRef();
  const [show, toggleFullscreen] = useToggle(false);
  const [isHovering, toggleHovering] = useToggle(false);
  const [chapters, setChapters] = useState([]);
  const pathname = usePathname();
  const isEnergyDashboard = pathname === routes.energy;

  const closeFullScreen = () => toggleFullscreen(false);

  const isFullscreen = useFullscreen(fullScreenRef, show, {
    onClose: closeFullScreen,
  });

  const onMouseLeave = useCallback(() => toggleHovering(false), [toggleHovering]);
  const onMouseEnter = useCallback(() => toggleHovering(true), [toggleHovering]);

  const onError = (e) => {
    console.log('onError from video:', e.target.error);
  };

  const onErrorCapture = (e) => {
    console.log('Captured error from video:', e.target.error?.code);
  };

  const onLoadCapture = useCallback(() => {
    if (!!chaptersRef?.current?.track.cues) {
      const cues = chaptersRef?.current.track.cues;

      if (cues['length'] > 0) {
        const chaps = Object.keys(cues).map((key) => {
          return {
            id: cues[key].id,
            startTime: cues[key].startTime,
            text: cues[key].text,
          };
        });

        setChapters(chaps);

        if (chaps?.length && isEnergyDashboard) {
          const crudeChapter = chaps.find(({ id }) => id === 'crude');

          ref.current.currentTime = crudeChapter.startTime;
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEnergyDashboard]);

  const [video, state, controls, ref] = useVideo(
    <video
      className='w-full rounded'
      src={src}
      poster={poster}
      playsInline
      preload='metadata'
      crossOrigin='anonymous'
      onError={onError}
      onErrorCapture={onErrorCapture}>
      {chaptersSrc && (
        <track
          kind='chapters'
          ref={chaptersRef}
          src={chaptersSrc}
          default
          onLoadCapture={onLoadCapture}
          onErrorCapture={() => {
            console.log('error loading chapters');
          }}
        />
      )}
    </video>
  );

  const showControls = useMemo(() => {
    return isHovering || state.paused;
  }, [isHovering, state.paused]);

  return (
    <div
      ref={fullScreenRef}
      className='relative flex rounded z-0 max-w-[800px] mx-auto w-full'
      style={{
        aspectRatio: '16 / 9',
      }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      data-testid='video-player'>
      <div
        className='flex w-full bg-teal-800 rounded'
        onClick={state.paused ? controls.play : controls.pause}
        data-testid='video-container'>
        {video}
      </div>
      {!state.duration && (
        <div className='absolute top-0 bottom-0 right-0 left-0 flex items-center justify-center'>
          <AntIcon name='loading1' className='!text-black/40 animate-spin' size={64} />
        </div>
      )}
      <div
        className='absolute bottom-0 right-0 left-0 w-full'
        style={{
          opacity: showControls ? 1 : 0,
          transition: 'opacity 0.3s ease-in',
        }}>
        <VideoControlBar
          ref={ref}
          controls={{ ...controls, toggleFullscreen }}
          state={{ ...state, isFullscreen }}
          chapters={chapters}
          showChapterLabels={showChapterLabels}
        />
      </div>
    </div>
  );
}

export default memo(VideoPlayer);
